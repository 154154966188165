import { PARKING_WIDGET_TYPE_ID } from '../../../shared/api/contentful/constants';
import {
	IPageSectionEntry,
	IParkingWidgetEntry,
} from '../../../shared/api/contentful/types';

export function splitParkingWidgetSection(sections: IPageSectionEntry[] = []) {
	const parkingWidgetSection = sections.find(isParkingWidgetSection);

	const otherSections = sections.filter(
		(section) => !isParkingWidgetSection(section),
	);

	return { parkingWidgetSection, otherSections };
}

function isParkingWidgetSection(
	section: IPageSectionEntry,
): section is IParkingWidgetEntry {
	return section.contentType === PARKING_WIDGET_TYPE_ID;
}
