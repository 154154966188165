import React, { FC } from 'react';
import { splitParkingWidgetSection } from '../../../content-sections/domain/use-cases/splitParkingWidgetSection';
import { ParkingSection, Sections } from '../../../content-sections/ui';
import { PageHeader } from '../../../shared/components';
import {
	IPageLayoutProps,
	PageLayout,
} from '../../../shared/layout/page/PageLayout';
import { IEnrichedPageEntry } from '../domain/models/IEnrichedPageEntry';

export interface IGenericPageProps extends IPageLayoutProps {
	page: IEnrichedPageEntry;
}

export const GenericPage: FC<IGenericPageProps> = ({
	page,
	children,
	...rest
}) => {
	const { parkingWidgetSection, otherSections } = splitParkingWidgetSection(
		page.fields.sections,
	);

	return (
		<PageLayout page={page} {...rest}>
			<PageHeader page={page} />
			{parkingWidgetSection && (
				<ParkingSection section={parkingWidgetSection} />
			)}
			{children}
			<Sections sections={otherSections} />
		</PageLayout>
	);
};
