import React from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import { removeUndefined } from '@move-frontend/utils';
import { getStaticPathsForGenericPages } from '../../features/app/pages/domain/use-cases/getStaticPathsForGenericPages';
import { contentful } from '../../features/shared/api/contentful';
import { IQuery } from '../../features/shared/router/models/IQuery';
import { getPageByPath } from '../../features/app/pages/domain/use-cases/getPageByPath';
import { GenericPage } from '../../features/app/pages/ui/GenericPage';
import { defaultLocale } from '../../features/shared/i18n/locale/constants/default';
import { getLocaleFromPath } from '../../features/shared/i18n/locale/getLocaleFromPath';
import { IEnrichedPageEntry } from '../../features/app/pages/domain/models/IEnrichedPageEntry';
import { isPreviewMode } from '../../features/app/preview/utils/isPreviewMode';

interface IStaticParams extends IQuery {
	locale: string;
	slug: string[];
}

interface IStaticProps {
	page: IEnrichedPageEntry;
}

export const getStaticPaths: GetStaticPaths<IStaticParams> = async () => {
	const paths = await getStaticPathsForGenericPages(contentful());

	return {
		paths,
		fallback: isPreviewMode(),
	};
};

export const getStaticProps: GetStaticProps<
	IStaticProps,
	IStaticParams
> = async ({ params }) => {
	const locale = getLocaleFromPath(params?.locale ?? '') ?? defaultLocale;
	const page = await getPageByPath(params?.slug ?? '', locale);

	if (!page) return { notFound: true };

	return {
		props: removeUndefined({
			page,
		}),
	};
};

const Page = (props: IStaticProps) => <GenericPage {...props} />;

export default Page;
